var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "page-broadcast internal-page"
  }, [_c('section', {
    staticClass: "container-detailed-content"
  }, [_c('section', {
    staticClass: "internal-generic-header"
  }, [_c('div', {
    staticClass: "search-header"
  }, [_c('div', {
    staticClass: "container-title"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t('broadcast.title')))])]), _c('div', {
    staticClass: "container-search"
  }, [_c('button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip.auto",
      value: {
        content: _vm.$t('broadcast.tooltip.broadcastDisplays'),
        offset: 10
      },
      expression: "{ content: $t('broadcast.tooltip.broadcastDisplays'), offset: 10 }",
      modifiers: {
        "auto": true
      }
    }],
    staticClass: "btn-question"
  }, [_c('svgLoader', {
    attrs: {
      "src": "/img/icon-question.svg",
      "color": "#000"
    }
  })], 1)])])]), _c('section', {
    staticClass: "wrapper-broadcast is-selected-item"
  }, [_c('div', {
    staticClass: "container-broadcasts-tabs"
  }, [_c('ul', {
    staticClass: "broadcasts-tabs"
  }, _vm._l(_vm.bradcastsRoutes, function (item, index) {
    return _c('li', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip.auto",
        value: {
          content: item.textTooltip,
          offset: 2
        },
        expression: "{ content: item.textTooltip, offset: 2 }",
        modifiers: {
          "auto": true
        }
      }],
      key: index
    }, [_c('router-link', {
      style: {
        'color': _vm.getterSelectedBot.botColor,
        'border-color': _vm.getterSelectedBot.botColor
      },
      attrs: {
        "to": {
          name: item.pathName,
          query: item.query || {}
        },
        "exact": ""
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1);
  }), 0)]), _c('div', {
    staticClass: "broadcast-content"
  }, [_c('router-view')], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }