import { mapGetters } from 'vuex'
import { SvgLoader } from '@/components'

export default {
  components: {
    SvgLoader
  },
  data () {
    return {
      bradcastsRoutes: [
        {
          name: 'Workplace',
          pathName: 'SendBroadcastToWorkplace',
          textTooltip: ''
        },
        {
          name: 'Whatsapp',
          pathName: 'SendBroadcastToWhatsapp',
          textTooltip: ''
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'getterSelectedBot'
    ])
  }
}
